import { useMemo } from 'react';

import { AUTH_ROUTES, ROOT_ROUTES, PRESCRIBER_ROUTES, ADMIN_ROUTES } from '@router';
import { PRESCRIBER, NOT_FOUND } from '@router/paths';
import Prescriber from '@pages/Prescriber';
import { RedirectRoot } from '@pages/RedirectRoot';
import { USER_ROLES, PROFILE_STATUSES } from '@utils/consts';
import AuthLayout from '@components/Layout/AuthLayout';
import RootLayout from '@components/Layout/RootLayout';

const routeRoles = {
  [USER_ROLES.PRESCRIBER_ROLE]: PRESCRIBER_ROUTES,
  [USER_ROLES.ADMIN_ROLE]: ADMIN_ROUTES,
};

export const useRouterPaths = (role, status, isMainLoading) => {
  return useMemo(() => {
    if (isMainLoading) {
      return [
        {
          component: status ? RootLayout : AuthLayout,
          routes: [...ROOT_ROUTES],
        },
      ];
    }

    // If user was rejected - redirect him to create profile page
    if (role === USER_ROLES.PRESCRIBER_ROLE && status === PROFILE_STATUSES.REJECTED) {
      return [
        {
          component: AuthLayout,
          routes: [...ROOT_ROUTES, ...AUTH_ROUTES],
        },
      ];
    }

    if (role === USER_ROLES.PRESCRIBER_ROLE && status !== PROFILE_STATUSES.ACTIVE) {
      return [
        {
          component: RootLayout,
          routes: [
            ...ROOT_ROUTES,
            {
              name: 'confirmationPending',
              path: PRESCRIBER.ROOT,
              exact: true,
              component: Prescriber.ConfirmationPending,
            },
            {
              path: NOT_FOUND,
              component: () => <RedirectRoot path={PRESCRIBER.ROOT} />,
            },
          ],
        },
      ];
    }

    if (routeRoles[role]) {
      return [
        {
          component: RootLayout,
          routes: [...ROOT_ROUTES, ...routeRoles[role]],
        },
      ];
    }

    return [
      {
        component: AuthLayout,
        routes: [...ROOT_ROUTES, ...AUTH_ROUTES],
      },
    ];
  }, [role, isMainLoading, status]);
};
